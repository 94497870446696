import * as Api from "./../../libs/api-client";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async search(_, condition) {
    return Api.dispatchGet(`/rateSetting/search`, condition);
  },
  async import(_, deliveryModel) {
    return Api.dispatchPost("/rateSetting/import", deliveryModel);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
